import { FlightAddOnsDetail } from './add-ons';
import { TravelerType } from './traveler';
import type { Optional } from './utils';

export enum FlightSelectionType {
  DEPART = 'depart',
  RETURN = 'return',
}

export enum FlightStopsType {
  MULTI_STOP = 'multistop',
  DIRECT = 'direct',
}

export enum FlightStopsTypeMultifare {
  MULTI_STOP = 'multistop',
  DIRECT = 'direct',
}

export enum FlightRouteType {
  INTERNATIONAL = 'intl',
  DOMESTIC = 'dom',
}

export enum FlightTimeFilter {
  EARLY = 'early',
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  NIGHT = 'night',
}

export enum FlightSort {
  LOWEST_PRICE = 'lowest',
  HIGHEST_PRICE = 'highest',
  DEPART_TIME = 'depart',
  ARRIVAL_TIME = 'arrival',
  DURATION = 'duration',
  RECOMMENDED = 'recommended',
}

export enum FlightCabinType {
  ECONOMY = 'economy',
  ECO_PREMIUM = 'ecopremium',
  BUSINESS = 'business',
  FIRST = 'first',
}

export enum FlightAirlinePreferences {
  SAME = 'same',
  MIX = 'mix',
}

export type FlightSearchType = 'oneway' | 'roundtrip' | 'multicity';

export type FlightFilters = {
  stops: number[];
  departTimes: FlightTimeFilter[];
  airlines: string[];
  price: number[];
  airlinePreferences: FlightAirlinePreferences[];
};

export type FlightSearchParams = {
  depAirport: string;
  arrAirport: string;
  depDate: string;
  retDate?: string;
  adult: number;
  child: number;
  infant: number;
  currency?: string;
  type?: string;
  sharedFlight?: string;
  surl?: string;
  cabin?: FlightCabinType;
};

export type FlightBaseSuggestion = {
  code: string;
  name: string;
  country: string;
  countryName: string;
  cityName: string;
  continent: string;
};

export type FlightSingleAirportSuggestion = FlightBaseSuggestion & {
  distance?: number;
};

export type FlightMultiAirportSuggestion = FlightBaseSuggestion & {
  airports?: FlightSingleAirportSuggestion[];
};

export type FlightAirportSuggestion = FlightSingleAirportSuggestion & FlightMultiAirportSuggestion;

export type FlightDeal = {
  arrAirport: string;
  arrAirportCity: string;
  arrAirportName: string;
  currency: string;
  depAirport: string;
  depAirportCity: string;
  depAirportName: string;
  depDate: string;
  price: number;
};

export type FlightCalendarFares = { [date: string]: number };

export type FlightCalendarFaresResponse = {
  depart: FlightCalendarFares;
  return?: FlightCalendarFares;
};

export type CalendarHolidayList = {
  [key: string]: string;
};

export type FlightPassengerFare = {
  price: number;
  tax: number;
  fuel: number;
  service: number;
  insurance: number;
  other: number;
  traPrice: number;
  traTax: number;
  traFuel: number;
  traService: number;
  traInsurance: number;
  traOther: number;
};

export type FlightTag = {
  color: string;
  icon: string;
  type: string;
  text: string;
};

export type AirportInfo = {
  city: string;
  name: string;
  tzOffset: number;
};

export type FlightBaggageInfo = {
  currency: string;
  price: number;
  weight: number;
  unit: string;
  sgn: string;
  type: string;
  qty: number;
  maxWeight?: number;
};

export type FlightLayoverInfo = {
  transitVisa: boolean;
  baggageRecheck: boolean;
  selfTransfer: boolean;
  selfTransferAirport: boolean;
  selfTransferTerminal: boolean;
  selfTransferAll: boolean;
};

export type FlightAirline = {
  code: string;
  name: string;
};

export type FlightAirport = {
  code: string;
  name: string;
  city: string;
  terminal: string;
};

export type FlightStopover = {
  airport: FlightAirport;
  duration: number;
};

export type FlightSegment = {
  code: string;
  isCodeShare: boolean;
  airline: FlightAirline;
  optAirline: FlightAirline;
  dptAirport: FlightAirport;
  arrAirport: FlightAirport;
  aircraft: string;
  dptTime: string;
  arrTime: string;
  duration: number;
  transitDuration: number | null;
  stopovers: FlightStopover[];
  issuedTime?: number;
  layoverInfo: FlightLayoverInfo | null;
  hasChanges: boolean;
};

export type FlightFarePricePax = {
  total: number;
  price: number;
  tax: number;
  other: number;
};

export type FlightFarePrice = {
  currency: string;
  display: number;
  displayBeforeDisc: number;
  displayDisc: boolean;
  discType: string;
  adult: FlightFarePricePax | null;
  child: FlightFarePricePax | null;
  infant: FlightFarePricePax | null;
  total: number;
  totalBeforeDisc: number;
};

export type FlightFarePaxAttribute = {
  refundable: boolean;
  reschedulable: boolean;
};

export type FlightFareInfo = {
  conditions: string[];
  conditionValues: {
    requestTime: string;
    type: string;
    value: number;
  }[];
};

export type FlightFareAttribute = {
  adult: FlightFarePaxAttribute;
  child?: FlightFarePaxAttribute;
  infant?: FlightFarePaxAttribute;
  refundPolicy?: FlightFareInfo;
  reschedulePolicy?: FlightFareInfo;
  benefits: string[];
  freeBaggage?: {
    type: string;
    qty: number;
    weight: number;
    maxWeight?: number;
  };
  cabinBaggage: {
    weight: number;
    qty: number;
    type: string;
  };
  bagUnit: string;
  personalItemQty?: number;
};

export type FlightFare = {
  id: string;
  sgn: string;
  isCombo: boolean;
  pricing: FlightFarePrice;
  pricingDifference?: Omit<FlightFarePrice, 'displayBeforeDisc' | 'totalBeforeDisc'>;
  lounge: boolean;
  priorityBoarding: boolean;
  attributes: FlightFareAttribute[];
  cabinName: string;
};

export type FlightLiveCrawlParams = {
  dptAirport: string;
  arrAirport: string;
  dptDate: string;
  rtnDate?: string;
  adult: number;
  child: number;
  infant: number;
  currency: string;
  tripType: string;
  lang: string;
  airline?: string;
};

export type FlightLiveCrawlResponse = {
  dptRequestId: string;
  rtnRequestId: string;
  dptAirport: {
    name: string;
    city: string;
    country: string;
  };
  arrAirport: {
    name: string;
    city: string;
    country: string;
  };
  messages: {
    msgId: string;
    variable: Record<string, string>;
  }[];
};

export type FlightLiveCrawlMonitorParams = {
  reqId: string;
  timestamp?: number;
  flightId?: string;
  fareId?: string;
  isCombo?: boolean;
};

export type Flight = {
  id: string;
  expiresAt: number;
  type: FlightStopsType;
  cabinType: FlightCabinType;
  stops: number;
  duration: number;
  tags: FlightTag[];
  attribute: {
    cabin: boolean;
    fba: boolean;
    meal: boolean;
  };
  segments: FlightSegment[];
  fares: FlightFare[];
};

export type FlightLiveCrawlInfo = {
  reqId: string;
  flights: {
    [flightKey: string]: Flight;
  } | null;
  timestamp: number;
  expiresAt: number;
};

export type FlightFacilities = {
  id: string;
  label: string;
  icon: ['fal' | 'far' | 'fas' | 'fab' | 'fak', string];
  available: boolean;
};

export type FlightFaresPolicy = {
  conditionValues: {
    value: number | string;
    type: string;
    requestTime: string;
  }[];
  conditions: string[];
};

export type FlightSearchHistory = FlightSearchParams & {
  /** saved history from blaze & forerunner doesn't contain these properties */
  depAirportProperties?: Optional<FlightAirportSuggestion, 'country' | 'countryName' | 'continent'>;
  arrAirportProperties?: Optional<FlightAirportSuggestion, 'country' | 'countryName' | 'continent'>;

  /** saved search type for recent search necessary */
  searchType?: FlightSearchType;
  cabinType?: FlightCabinType;
};

export type FlightSearchFormHistory = {
  airportName: string;
  airportDesc: string;
  value?: Optional<FlightAirportSuggestion, 'country' | 'countryName' | 'continent'>;
};

export type FlightFareAttributeItem = {
  id: string;
  label: string;
  icon: any;
  type: string;
  info?: boolean;
  value?: boolean;
};

export type FlightFareAttributeGroup = {
  baggages: FlightFareAttributeItem[];
  flexibilities: FlightFareAttributeItem[];
  benefits: FlightFareAttributeItem[];
};

export type FlightDetail = {
  cabinName: string;
  general: Pick<FlightSegment, 'code' | 'aircraft' | 'isCodeShare' | 'airline' | 'optAirline'>;
  timeline: Pick<FlightSegment, 'dptAirport' | 'arrAirport' | 'dptTime' | 'arrTime' | 'stopovers'> & {
    layoverInfo?: {
      icon: string;
      label: string;
      infoAction?: Function | undefined;
    }[];
    arrDateTimeTerminal?: string;
    depDateTimeTerminal?: string;
    duration: number;
  };
  facilities: FlightFareAttributeItem[];
  attributes?: FlightFareAttributeItem[];
  layoverInfo?: {
    icon: string;
    label: string;
    infoAction?: Function;
  }[];
};

export type LiveCrawlStatus = {
  [reqId: string]: { done: boolean; progress: number; count: number; lastUpdate: number };
};

export type LiveCrawlSession = { [reqId: string]: ReturnType<typeof window.setTimeout> };

export type FlightLiveCrawlDetail = {
  arrAirport: {
    city: string;
    name: string;
    country: string;
  };
  dep: {
    reqId: string;
  };
  depAirport: {
    city: string;
    name: string;
    country: string;
  };
  ret?: {
    reqId: string;
  };
};

export type FlightConditionTerm = {
  code: string;
  airline: FlightAirline | FlightAirline[];
  depAirport: string;
  arrAirport: string;
  type: string;
  stops: number;
  rescheduleable: boolean;
  refundable: boolean;
  refundPolicy?: FlightFaresPolicy | null;
  reschedulePolicy?: FlightFaresPolicy | null;
};

export type FlightInfoDetail = {
  title: string;
  type: 'refundable' | 'rescheduleable' | 'selftransfer' | 'multipleairline' | 'norefundable' | 'norescheduleable' | '';
  infoHeader?: FlightConditionTerm;
};

export type FlightSearchResponse = {
  depFlight: Flight[];
  retFlight?: Flight[];
};

export type FlightTimelineComparison = {
  start: {
    time: string;
    tz: number;
    airportCode: string;
  };
  end: {
    time: string;
    tz: number;
    airportCode: string;
  };
};

export type FlightShareParam = {
  dptAirport: string;
  arrAirport: string;
  dptDate: string;
  rtnDate?: string;
  adult: number;
  child: number;
  infant: number;
  currency: string;
  lang: string;
  tripType: FlightCabinType;
  dptFlightId: string;
  rtnFlightId: string;
  isCombo: boolean;
  dptFlightSgn: string;
  rtnFlightSgn?: string;
};

export type ScheduledFlightAirportParams = {
  code: string;
  name: string;
};

export type ScheduledFlightAirportDetailParams = ScheduledFlightAirportParams & {
  enName: string;
  city: string;
  terminal: string;
};

export type ScheduledFlightParams = {
  dptAirport: ScheduledFlightAirportParams;
  arrAirport: ScheduledFlightAirportParams;
  aircraftName: string;
  dptDate: string;
  arrDate: string;
  duration: number;
};

export type FlightHotelParams = {
  id: string;
  name: string;
  slug: string;
  star: number;
  photo: string;
  reviews: {
    rating: number;
    count: number;
  };
  city: string;
  district: string;
};

export type FlightsHotelInCityParams = {
  city: string;
  cityId: string;
  hotels: FlightHotelParams[];
};

export type FlightByCode = {
  flightNo: string;
  airline: {
    name: string;
    iataCode: string;
    icaoCode: string;
  };
  popularCountries: string[];
  schedules: {
    date: string;
    headlineFlight: Omit<ScheduledFlightParams, 'dptAirport' | 'arrAirport'> & {
      dptAirport: ScheduledFlightAirportDetailParams;
      arrAirport: ScheduledFlightAirportDetailParams;
    };
    todayFlights: ScheduledFlightParams[];
    upcomingFlights: ScheduledFlightParams[];
  }[];
  pricingList: {
    dptAirport: {
      code: string;
      city: string;
    };
    arrAirport: {
      code: string;
      city: string;
    };
    finalPrice: number;
    beforePrice: number;
    disc: number;
    currency: string;
    dptDate: string;
    flightCode: string;
  }[];
  dptHotel: FlightsHotelInCityParams;
  arrHotel: FlightsHotelInCityParams;
  dptRoute: {
    airport: ScheduledFlightAirportParams & { enName: string; cityId: string };
    destinations: (ScheduledFlightAirportParams & { enName: string })[];
  };
  arrRoute: {
    airport: ScheduledFlightAirportParams & { enName: string; cityId: string };
    destinations: (ScheduledFlightAirportParams & { enName: string })[];
  };
};

export type FlightSelectionTypeUserPreferences = {
  priceLevels: {
    priceGroup: string;
  }[];
  priceLevel: number;
  priceRange: number[];
  activeFilters: Omit<FlightFilters, 'departTimes'> & { departTimes: number[] };
  activeSort: string;
};

export type FlightUserPreferences = {
  flights: ({ type: string } & FlightSelectionTypeUserPreferences)[];
} | null;

export type FlightBaggageSegment = {
  code: string;
  freeBaggage: {
    type: string;
    qty: number;
    weight: number;
    unit: string;
    maxWeight?: number;
  };
  dptAirport: FlightAirport;
  arrAirport: FlightAirport;
  options: {
    currency: string;
    price: number;
    weight: number;
    unit: string;
    sgn: string;
    type: string;
    qty: number;
    maxWeight?: number;
  }[];
};

export type FlightBaggage = {
  stops: number;
  dptAirport: FlightAirport;
  arrAirport: FlightAirport;
  segments: FlightBaggageSegment[];
};

export type FlightDetailFaresParams = {
  attributes: FlightFareAttribute[];
  cabinName: string | string[];
  lounge: boolean | boolean[];
  priorityBoarding: boolean | boolean[];
};

export type FlightDetailParams = Omit<Flight, 'id' | 'expiresAt' | 'attribute' | 'fares'> & {
  fares: FlightDetailFaresParams | FlightDetailFaresParams[];
};

export type FlighOrderDetailParams = Omit<Flight, 'id' | 'expiresAt' | 'attribute' | 'fares'> & {
  fares: FlightDetailFaresParams | FlightDetailFaresParams[];
  addOns: FlightAddOnsDetail;
};

export type FlightPriceDetail = FlightFarePrice & {
  totalPax: Record<TravelerType, number>;
  baggage?: Array<FlightBaggageInfo & { bgKey: string }>;
  addOns?: { [key: string]: { count: number; price: number } };
};
